"use client";

import { useEffect } from "react";
// import { reportError } from "@/utils/helpers/error-reporting";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Report the error to the back-end
    // reportError(error);
  }, [error]);

  return (
    <html>
      <body>
        <div className="flex pt-12 justify-center">
          <div className="max-w-md w-full bg-background shadow-lg rounded-lg p-8">
            <h2 className="text-2xl text-center mb-4">Stunt Double had an accident 🏍️</h2>
            <p className="text-center mb-4">
              We&apos;ve encountered an unexpected error. Our team has been notified and is working
              on a fix.
            </p>
            {error.message && (
              <p className="text-center mb-4 max-w-prose">Error: {error.message}</p>
            )}
            <button
              onClick={() => reset()}
              className="w-full bg-primary-500 font-bold py-2 px-4 rounded"
            >
              Try again
            </button>
          </div>
        </div>
      </body>
    </html>
  );
}
